import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stack, Box } from "@mui/material";

import Header from "../../components/Conversation/Header";
import Footer from "../../components/Conversation/Footer";
import Messages from "../../components/Conversation/Messages";
import Logo from "../../assets/Images/logo.ico"

const GeneralApp = () => {
  const [messages, setMessages] = useState([]);
  const [ws, setWs] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [wsStatus, setWsStatus] = useState('connecting');
  const messagesEndRef = useRef(null);

  const handleSendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log('Message from input ', message);
      setMessages((prevMessages) => [...prevMessages,
      {
        type: "prompt",
        message: message,
        message_id: `input_${new Date().getTime()}_${Math.random().toString(36).substring(2, 15)}`,
      }]);

      setIsProcessing(true);
      ws.send(JSON.stringify({ action: "ask", message: message }));
    } else {
      console.log('WebSocket is not connected. Unable to send message.');
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      const permission = await Notification.requestPermission();
      console.log("Notification permission:", permission);
    }
  };

  const connectWebSocket = useCallback(() => {
    setWsStatus('connecting');

    let socket;

    socket = new WebSocket(process.env.REACT_APP_ASK_IDA_API);

    setWs(socket);

    socket.addEventListener('open', (event) => {
      setIsProcessing(false);
      
      console.log('WebSocket is open now.');
      setWsStatus('connected');

    });

    socket.addEventListener('error', (event) => {
      console.log('WebSocket connection error', event);
      setWsStatus('error');

      setIsProcessing(false);
    });

    socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed', event);
      setWsStatus('closed');
      setTimeout(() => {
        console.log('Attempting to reconnect...');
        setWsStatus('reconnecting');
        connectWebSocket();
      }, 5000);
    });

    socket.addEventListener('message', (event) => {
      console.log('Message from server ', event.data);

      const json_data = JSON.parse(event.data).message;
      const json_message = JSON.parse(json_data);
      console.log('JSON Data:', json_message);

      setMessages((prevMessages) => [...prevMessages, {
        type: "answer",
        message: json_message.message,
        message_id: json_message.message_id,
      }]);

      setIsProcessing(false);

      if (!document.hasFocus() && Notification.permission === "granted") {
        new Notification("New Message", {
          body: "IDA has sent you a new reply.",
          icon: Logo,
        });
      }
    });
  }, []);

  useEffect(() => {
    requestNotificationPermission();
    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [connectWebSocket]);

  return (
    <Stack
      padding={2}
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Chat Header */}
        <Header websocketStatus={wsStatus} />

        {/* Messages */}
        <Box
          p={4}
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            bgcolor: "#FFF",
          }}
        >
          <Messages messages={messages} />
          <div ref={messagesEndRef} />
        </Box>

        {/* Chat Footer */}
        <Footer onSendMessage={handleSendMessage} disable={isProcessing} />
      </Box>
    </Stack>
  );
};

export default GeneralApp;
